import axios from 'axios'
import { interceptor } from '@/application/utils/interceptor.js'

const domain = process.env.VUE_APP_API_PATH
const getAuthRequestPackagesApi = `${domain}api/v1/dashboard/auth-packages/`
const getAuthRequestPaddleLinkApi = (packageId) => `${domain}api/v1/dashboard/auth-buy-package/${packageId}/`
const authSandboxUsersApi = `${domain}api/v1/dashboard/auth-sandbox-users/`
export const getAuthRequestPackages = () => interceptor(axios).get(getAuthRequestPackagesApi)
export const sendAuthRequestPaddleLink = (packageId, data) => interceptor(axios).post(getAuthRequestPaddleLinkApi(packageId), data)
export const addAuthSandboxUser = (data, headers) => interceptor(axios).post(authSandboxUsersApi, data, { headers })
export const deleteAuthSandboxUser = (data, headers) => interceptor(axios).delete(authSandboxUsersApi, { data, headers })
